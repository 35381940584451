@import '../../style/themes/index';
@import '../../style/mixins/index';

@avatar-prefix-cls: ~'@{ant-prefix}-avatar';

.@{avatar-prefix-cls} {
  .reset-component;

  position: relative;
  display: inline-block;
  overflow: hidden;
  color: @avatar-color;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: @avatar-bg;

  &-image {
    background: transparent;
  }

  .avatar-size(@avatar-size-base, @avatar-font-size-base);

  &-lg {
    .avatar-size(@avatar-size-lg, @avatar-font-size-lg);
  }

  &-sm {
    .avatar-size(@avatar-size-sm, @avatar-font-size-sm);
  }

  &-square {
    border-radius: @avatar-border-radius;
  }

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.avatar-size(@size, @font-size) {
  width: @size;
  height: @size;
  line-height: @size;
  border-radius: 50%;

  &-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
  }

  &.@{avatar-prefix-cls}-icon {
    font-size: @font-size;

    > .@{iconfont-css-prefix} {
      margin: 0;
    }
  }
}

@font-family: 'Circular Std', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';@font-variant-base: normal;@font-feature-settings-base: normal;@primary-color: #031F48;@info-color: @primary-color;@success-color: #42C169;@processing-color: @blue-3;@error-color: #C7483E;@highlight-color: @red-5;@warning-color: @gold-6;@normal-color: #d9d9d9;@text-color: #031F48;@text-color-secondary: #5d5d5d;@heading-color: #031F48;@input-icon-color: #C4C4C4;@border-radius-base: 5px;